.smbox{
    background-color: #1a1a1a ;
    border-radius: 10px ;
    color:#f8f9fa
}



.mysmallcard{
   
    border-radius: 18px;
  
   /* box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
  */
  
    margin:3px;

}








