

:root {
  --header-height: 3rem;
/*  --nav-width: 48px;  */
--nav-width: 52px;



--first-color: #1a1a1a;
--first-color-alt: #1a1a1a;
 --white-color: #1a1a1a;

--my-color: #f8f9fa;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}






a {
  text-decoration: none;
}

ul {
  list-style: none;
}

main {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-size: 1rem;
  transition: 0.5s;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  padding: 0 1rem;  
  transition: 0.5s;
}

.header-toggle {
  font-size: 1.25rem;
  cursor: pointer;
  color: var(--first-color);
}




.sidebar {
  position: fixed;
  top: 0;
  left: -30%;
 /* height: 100%;*/
  height: 100vh;
  width: var(--nav-width);
  background-color: var(--first-color);
  padding-top: 1rem;
  transition: 0.5s;
 
}











.nav {
  display: flex;
  flex-direction: column;
 /* justify-content: space-between;  */
  overflow: hidden;
 /* height: 100%;*/
 height: 100vh;

 overflow-y: auto; 
}

















.nav-logo,
.nav-link {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1.5rem;
 /* padding: 0.5rem 0 0.5rem 1.5rem;  */
}

.nav-logo {

  margin-bottom: 2rem;
}

.nav-logo-icon {
  font-size: 1.25rem;
  color: var(--black-color);
  cursor: pointer;
}

.nav-logo-name {
  font-weight: 700;
 color: "white";
}

.nav-link {
  position: relative;
  color: white;
  transition: 0.5s;
  margin-bottom: 1.5rem;
}

.nav-link:hover {

color: var(--black-color);
background-color: rgb(149, 146, 146);


}



.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  width: 3px;
  transition: 0.5s;
}

.show {
  left: 0;
}

.space-toggle {
  padding-left: calc(var(--nav-width) + 1rem);
}

@media screen and (min-width: 768px) {
  main {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .sidebar {
    left: 0;
    padding: 1rem 0 0 0;
  }

  .show {
    width: calc(var(--header-height) + 150px);
  }

  .space-toggle {
    padding-left: calc(var(--nav-width) + 160px);
 
  }



}























