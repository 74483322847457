

.pageNumbers li.active {
  background-color: rgb(17, 189, 223);
  color: rgb(78, 9, 240);
}

  
.pageNumbers li {
   
  size: small;
  margin: 5px;
  border-radius: 10px;
/*  border: 2px solid rgb(8, 7, 7);*/
  cursor: pointer;
}





    /*   Pagination  color*/
/*
    .pageNumbers {
      list-style: none;
      display: flex;
      justify-content: center;
    }
    
    .pageNumbers li {
   
      size: small;
      margin: 5px;
      border-radius: 10px;
      border: 2px solid rgb(8, 7, 7);
      cursor: pointer;
    }
    
    .pageNumbers li.active {
      background-color: rgb(17, 189, 223);
      color: rgb(78, 9, 240);
    }
    
    .pageNumbers li button {
      background-color: transparent;
      border: none;
      color: rgb(11, 182, 194);
      font-size: 1.5rem;
      cursor: pointer;
    }
    .pageNumbers li button:hover {
      background-color: rgb(14, 217, 231);
      color: rgb(19, 73, 173);
    }
    .pageNumbers li button:focus {
      outline: none;
    }
    
    .loadmore {
      padding: 1rem;
      background-color: transparent;
    
      color: rgb(28, 27, 27);
      font-size: 1.2rem;
      border: 1px solid rgb(23, 19, 19);
      cursor: pointer;
    }
    
*/






