.myCard22 {

    grid-template-columns: 300px;
    grid-template-rows: 210px 250px 20px;
    grid-template-areas: "image" "text" "stats";
    border-radius: 18px;
    background-color: #282829;
    color:white;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.9);
   /* font-family: roboto;  */
    text-align: justify;
    cursor: pointer;
    margin: 1px;   
    transform-style: preserve-3d;
    transform: perspective(1000px);
  }
  .rgb22::after {
    content:"";
    background: linear-gradient(45deg,
    #ff0000 0%, 
    #fa4444 0%, 
    #00fffb 10%,
    #f203ff 20%,
    #037dff 30%, 
    #3f59da 40%,
    #e000f9 50%, 
    #1c7fee 60%, 
    #5f15f2 70%, 
    #bb00ff 80%, 
    #ff00dd 90%, 
    #ff0000 100%  )
    repeat 0% 0% / 300% 100%;
    position: absolute;
    inset: -3px;
    border-radius: 16px;
    filter: blur(8px);
    transform: translateZ(-1px); /*or z-index */
    animation: rgb 6s linear infinite;
  }
  @keyframes rgb22 {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
  .js-tilt-glare {
    border-radius: 18px;
  }
