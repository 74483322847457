
.click{
    
    background-color:#343a40 ;
    display: flex;
    width: 94%;
    margin: 12px;
    padding: 5px;
    border-radius: 15px;
    margin-top: 30px;
  }
  
  
  
  
  .click:hover {
      /*opacity: 0.3;*/
  
      background-color: #0A0519;
      
     border: 1px solid #26F8FF;
      transform: scale(1.03);     /* Equal to scaleX(2) scaleY(2) */
      transition-duration: 0.1s;
     /*transform: scale(0.6);*/
  }
  