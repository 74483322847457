



/*  leftsidebar Scroll bar Css (New Update in 20/5/23)  */



/* Style the scrollbar */
.nav::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  border-radius: 4px; /* Set the desired border radius for the scrollbar */
  background-color: #f8f9fa; /* Set the background color of the scrollbar track */
}


/* Style the scrollbar thumb */
.nav::-webkit-scrollbar-thumb {
  background-color: #55595c; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the desired border radius for the scrollbar thumb */
}

/* Style the scrollbar thumb on hover */
.nav::-webkit-scrollbar-thumb:hover {
  background-color: #55595c; /* Set the color of the scrollbar thumb on hover */
}







html{
font-family: 'proxima_nova_rgregular'
}





.RightBox{

background-color: rgb(0, 0, 0);
color: white;
margin:0;
padding:0;
z-index:8;
/* top:0;*/
 margin-top: -17px;
right:0; 
position:fixed;
width:300px;
height:100%;  


}




.textbold{
font-weight: bold;
}

.flexBox{
display: flex;
}


.buttonBox{
padding: 2px;
width: 100%;
}

.buttonWidth{
/*
width: 100%;
*/

font-size: 12px;
  padding:9px;
  padding-left: 20px;
  padding-right: 20px;

}




.tableBodys {
width: 150px;
text-align: center;
word-wrap: break-word
}



td, th {
text-align: center;
}



.imgBox{
flex-wrap: wrap;
display: flex; 
justify-content: center;
}



.imgButton{
  font-size: 12px;
  padding:9px;
  padding-left: 20px;
  padding-right: 20px;
margin-left: 8px;
margin-bottom: 5px;

}



.tableBox{
background-color: white;
}

.tableDiv{
overflow-y:scroll;  
/*height:50vh;*/
height:70vh;   
margin-top: 1%;

}


.tableDiv thead th {
position: sticky;
top: 0;
}


.UserKyc{
  display: flex;
   width: 100px;
}

.textButton{
  width: 50px
}



.totalData{
margin-top: 20px;
display:flex;
justify-content: center;
}

.textSize{

font-size: 20px
}


/*
.pageNumberList{
width:40px;
text-align:center;
padding-top:5px
}
*/


.buttonBox{
display: flex;
}


.buttomPadding{
padding-left: 5px;
}






.pageList{
font-size: 15px ;
font-weight: bold;
width: 40px;
text-align: center;
/* padding: 8px; */
 padding-top:8px;  
border: 2px solid gray;
}








th.sticky {
left: 0;
z-index: 1;
}
th.sticky2 {
left: 95px;
z-index: 1;
}




td.sticky{
left: 0;
position: sticky;
}
td.sticky2 {
left: 95px;
position: sticky;
}



.custom-modal {
border: 2px solid #131313;
box-shadow: 0 0 20px rgba(0.9, 0.9, 0.9, 0.9);
backdrop-filter: blur(5px);


position: relative;
/* backdrop-filter: blur(20px);
*/
}




/*
.custom-modal::before {
content: '';
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
backdrop-filter: blur(5px);
z-index: 20;
}
*/





.inputTable{

/*padding: 12px;*/
padding: 8px;
border-radius: 10px;
border: 1px solid #0a0b0b;
background-color: #f8f9fa;
color: #0a0b0b;
}




.popupBox{
z-index: 1000;
width:100%;
 height:100%;  
 border:"2px solid black";
 box-shadow: 10px 5px 5px rgb(14, 14, 14);
}




