
.email {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.email-header {
  display: flex;
  flex-wrap: wrap;
}

.email-header-item {
  margin-right: 20px;
}

.email-content {
  margin-top: 10px;
}


.email-dynamicEntry{
  font-weight: bold;
}












.tableBodys {
    width: 150px;
    text-align: center;
    word-wrap: break-word
  }
  
  td, th {
    text-align: center;
  }
  


  .buttonBox{
    display: flex;
 }


 .buttomPadding{
  padding-left: 5px;
 }






 .tableBody{
  width:"200px"
}





.popupBox{
  width:100%;
   height:100%;
}







.totalData{
  display: flex;
  justify-content: center;
}



.textSize{

  font-size: 20px
}





    
th.sticky, td.sticky {
  position: sticky;
  left: 0;
  z-index: 1;

}


th.sticky2, td.sticky2 {
  position: sticky;

left: 106px;
  z-index: 1;

}




th.sticky{
/*  background-color: #212529;*/
}


th.sticky2{
 /* background-color: #212529;*/
}

td.sticky{
 /* background-color: #fff;*/
}

td.sticky2{
 /* background-color: #fff;*/
}







  
.tableBox{
  background-color: white;
}

.tableDiv{
  overflow-y:hidden; 
  height:auto;
  padding-top:30px;
  
}




.flexBox{
  display: flex;
}



.buttonBox{
  padding: 2px;
  width: 100%;
}

.buttonWidth{
  width: 100%;
}









