


.box{
    padding-top: 80px;
    width:100% ;
    overflow-x: hidden;
   
}




.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }




